<template>
  <div class="higtlight" >
    <section class="about-bg">
	  <img   v-imgUrl="banners.message" class="nav-bg">
      <div class="bg"></div>
      <p class="mgs-title">Say Hello~</p>
      <!-- 评论框 -->
      <div class="input-main">
        <div class="input-box main">
          <div class="userbox">
            <div class="user-img" v-if="user.id">
            	<img :src="user.avatar_url">
            	<h4>{{user.name}}</h4>
            </div>
            <div class="user-img" v-else>
            	<img :src="`https://avatars.dicebear.com/v2/identicon/id-.svg`" alt="">
            	<h4>未登录</h4>
            </div>
          </div>

          <div class="textbox">
            <Input 
              v-model="content" 
              type="textarea" 
              :autosize="{minRows: 4, maxRows: 8}" 
              :maxlength="400"
              :placeholder="textarea" />
            <div class="submit-box">
              <!-- <div class="ykname">
                <Input v-model="message.name" placeholder="游客可以选填昵称" style="width: 120px" v-if="!user"/>
              </div> -->
              <Button type="primary" @click="submitMessage" >
                <Icon type="ios-create" />
                提交评论
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 评论列表 -->
    <MyLoading v-if="loading"></MyLoading>
    <section class="main" v-else>
		<div class="none" v-if="messageList.length<1">还没有留言，快来抢沙发。</div>
      <div class="commentList" v-for="(item, index) in messageList" :key="index" v-else>
        <div class="user-ava" >
          <img v-if="item.user" 
            :src="item.user.avatar_url" 
            onerror="this.src='https://avatars.dicebear.com/v2/identicon/id-undefined.svg'"
            alt="hasLogin"
          >
          <img v-else 
            src="https://avatars.dicebear.com/v2/identicon/id-undefined.svg"
            alt="unLogin"
          >
        </div>

        <div class="comment-main">
          <div class="comment-box animate03">
            <div class="username"> 
              <span>
                <Icon type="md-person" />
               {{item.user ? item.user.name : '游客'}}
               <em v-if="item.user && item.user.is_admin==1">(博主)</em>
               <span class="created"><Icon type="ios-time-outline" />{{item.create_time}}</span>
              </span>
              <span class="floor">{{item.id}}楼</span>
            </div>
            <div class="com_detail" v-html="item.content" v-highlight></div>
            <div class="comment-menu">
              <!-- 自己的留言显示删除按钮 -->
              <Poptip
                confirm
                placement="left"
                title="是否删除该留言?"
                @on-ok="deleteComment(item)">
                <Icon type="md-trash" v-if="item.user_id == user.id"/>
              </Poptip>
              <Tooltip content="回复留言">
                <i class="iconfont lv-icon-xiaoxi2" @click="handleReply(item, item)" v-if="item.user_id == user.id"></i>
              </Tooltip>
            </div>
			
			
            <!-- 回复功能 -->
            <div class="reply-box" v-for="(reply, _index) in item.reply" :key="_index">
              <p class="reply-header">
                <span>
                  {{reply.user.name}}
                  <em v-if="reply.user.is_admin==1">(博主)</em>
                  <span class="noml">回复</span>
                  <span>{{reply.topicUser ? reply.topicUser.name : '游客'}}</span>
                  <em v-if="reply.is_admin && reply.is_admin==1">(博主)</em>
                  <span class="created"><Icon type="ios-time-outline" /> {{item.create_time}}</span>
                </span>
              </p>
              <div class="com_detail" v-html="reply.content" v-highlight></div>
              <div class="comment-menu">
                <!-- 自己的留言显示删除按钮 -->
                <Poptip
                  confirm
                  placement="left"
                  title="是否删除该回复?"
                  @on-ok="deleteReply(reply)">
                  <Icon type="md-trash" v-if="reply.user_id == user.id"/>
                </Poptip>
                <Tooltip content="回复留言">
                  <i class="iconfont lv-icon-xiaoxi2" @click="handleReply(item, reply)" v-if="item.user_id == user.id"></i>
                </Tooltip>
              </div>
			  
            </div>
          </div>
          <!-- 回复评论 -->
          <div class="reply-text" v-if="item.id == reply_show_id">
            <Input 
              v-model="replyContent" 
              ref="textarea"
              type="textarea" 
              :autosize="{minRows: 3, maxRows: 8}" 
              :maxlength="400"
              :placeholder="replyTextarea" />
            <div class="reply-button">
              <Button class="cancel" @click="cancelReply">
                取消回复
              </Button>
              <Button type="primary" @click="submitReply" >
                <Icon type="ios-create" />
                回复留言
              </Button>
            </div>
          </div>
        </div>
      </div>
      <NewPage :pageModel="pageModel" @selectList="selectRoleList" v-if="pageModel.total>pageModel.list_rows"></NewPage>
    </section>
	

  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {marked} from 'marked'
import '@/assets/style/message.scss'

export default {
  data() {
    return {
      textarea: '支持markdown语法，尾部2个空格后回车才会换行，最长400个字',
      replyTextarea: '',
      replyContent: '',
      loading: true,
      messageList: [],
      reply_show_id: 0,
      topicUserId: '',
     
    content: '',
    

      pageModel: {
        page: 1,
        list_rows: 6,
		total:0
      },
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'banners'
    ]),
    compiledMarkdown: function () {
      return marked(this.detail.content, { sanitize: false })
    }
  },
  created() {
    this.getMessage()
  },
  methods: {
    // 获取留言
    getMessage() {
		
		let param = {
			page:this.pageModel.page,
			list_rows:this.pageModel.list_rows
		}
      this.$Apis.getMessageList(param).then(res => {
    
        this.loading = false
        
        this.messageList = res.data.data
		this.pageModel.total = res.data.total
        // 转markdown语法
        this.messageList.forEach(item => {
          item.content = marked(item.content, { sanitize: false })
          if (item.reply.length > 0) {
            item.reply.forEach(reply => {
              reply.content = marked(reply.content, { sanitize: false })
            })
          }
        })
      }).catch(() =>{})
	  
	 
	 
    },    
	
	
	// 提交留言
	submitMessage() {
		if(this.content==""){
			return this.$Message.success('请先输入留言内容')
		}
		let user_id = this.user.id?this.user.id:0;
		let param = {
			content:this.content,
			user_id:user_id
		}
	  this.$Apis.addMessage(param).then(res => {
		  this.$Message.success('留言成功')
			this.getMessage()
			this.content=''
	  }).catch(() =>{})
	},
	
	
	// 回复留言
	handleReply(item, reply) {
	  if (!item.user) {
	    this.$Message.error('不能回复游客！')
	    return
	  }
	this.reply_show_id = item.id
	 this.topicUserId = reply.user.id
	this.replyTextarea = '回复' + reply.user.name + '：'
	 this.replyContent = ''
	setTimeout(() => {
	  this.$refs.textarea[0].focus()
	}, 200)
	
	},
	//取消回复
	cancelReply() {
	  this.reply_show_id = 0
	},
	// 提交回复
	submitReply() {
		if(this.replyContent==""){
			return this.$Message.success('请先输入回复内容')
		}
		let user_id = this.user.id?this.user.id:0;
	  let params = {
	    content: this.replyContent,
	    message_id: this.reply_show_id,
	    topic_user_id: this.topicUserId,
		 user_id: user_id,
	  }
	
	  this.$Apis.replyMessage(params).then(res => {
	    this.getMessage()
	    this.$Message.success('留言回复成功')
	    this.cancelReply()
	  }).catch(() =>{})
	},
	//分页处理
    selectRoleList(val) {
	this.pageModel.page = val
      this.getMessage()
    },
  
    // 删除自己的留言
    deleteComment(item) {
		let params = {
		  message_id: item.id
		}
      this.$Apis.delMessage(params).then(res => {
       
        this.$Message.success('删除留言成功')
		 this.getMessage()
      }).catch(() =>{})
    },
	
	//删除字段的回复
    deleteReply(item) {
		let params = {
		  reply_id: item.id
		}
      this.$Apis.delMessageReply(params).then(res => {
        this.getMessage()
        this.$Message.success('删除回复成功')
      }).catch(() =>{})
    },


  }
}
</script>

<style scoped lang="scss">
	
	.higtlight{
		 height: 100%;
		 overflow: hidden;
		 background :#ecf4ff;
	}
	.none {
		font-size: 14px;
		text-align: center;
		height: 200px;
		line-height: 200px;
	}
	.nav-bg{
		width: 100%;
		height: 100%;
	}
.about-bg{
  width :100%;
  height: 500px;
  position: relative;
  .bg-img {
    width: 100%;
    position :absolute;
    top :0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
	}
 //  .bg{
 //    background :#ecf4ff;
 //    mix-blend-mode: multiply;
 //    height: 100%;
	// }
  .mgs-title{
    text-align: center;
    font-size: 40px;
    color: #fff;
    // z-index: 100;
    position: absolute;
    top: 12%;
    width: 220px;
    margin-left: -110px;
    left: 50%;
    font-weight: bold;
	}
	}
.input-main{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.comment-menu{
  display: flex;
  justify-content: flex-end;
  color: #657f86;
  padding :0 10px 5px 0;
  i{
    font-size: 20px;
    margin-left :10px;
    cursor: pointer;
	}
  .lv-icon-xiaoxi2{
    top: 2px;
    position: relative;
}
}
.comment-main{
  flex: 1;
  }
.reply-text,
.reply-button{
  margin-top: 10px;
  }
.reply-box{
  margin: 0px 10px 7px;
  border: 1px solid #ecf0f1;
  .reply-header{
    display: flex;
    justify-content: space-between;
    background: #ecf0f1;
    margin: 0;
    padding: 8px 9px;
    span{
      color :#f7576c;
      font-weight: bold;
      margin: 0 3px;
	  }
    em{
      color: #009688;
      font-weight :bold;
	  }
    .created{
      margin-left: 10px;
      font-weight: 100;
      color: #7f8c8d;
	  }
    .noml{
      color: #7f8c8d;
	  }
  &:hover{
    box-shadow: 2px 2px 15px #d2e7fd;
}
}
}

.cancel{
  margin-right: 10px;
}
@media screen and (max-width: 750px){
  .comment-box{
    box-shadow: 2px 2px 15px #d2e7fd;
	}
  .about-bg{
    height :320px;
    .mgs-title{
      font-size :24px;
	  }}}
</style>
